<template>
    <div data-scroll-id="Brands" :class="{ lightMode }" class="brand-list">
        <img
            src="@/assets/images/brand-background.png"
            class="background-image"
            alt=""
        />
        <div class="brand-content">
            <div
                v-for="(item, index) in brandList"
                :key="index"
                :class="{ revers: item.revers }"
                class="brand-list-item"
            >
                <div
                    data-scroll
                    :data-scroll-speed="imageSpeed"
                    @click="changeRoute(item.slug)"
                    class="image-container"
                >
                    <div class="image-contain">
                        <img
                            :src="item.path"
                            data-scroll
                            :data-scroll-id="`inside-scale${index}`"
                            class="image"
                            alt=""
                        />
                    </div>
                    <div
                        data-scroll
                        data-scroll-speed="1.5"
                        class="name-container"
                    >
                        <div v-html="item.logo"></div>
                        <div
                            :style="{ background: item.brandColor }"
                            class="bg-container"
                        ></div>
                    </div>
                </div>
                <div
                    data-scroll
                    data-scroll-direction="horizontal"
                    class="content"
                >
                    <header-title
                        @click.native="changeRoute(item.slug)"
                        text-color="white"
                        :name="item.title"
                    />
                    <p class="description">{{ item.teaser }}</p>
                    <div
                        @click="changeRoute(item.slug)"
                        class="explore-container"
                    >
                        <svg
                            class="arrow-icon"
                            width="22.23"
                            height="11.775"
                            viewBox="0 0 22.23 11.775"
                        >
                            <g
                                id="Group_55"
                                data-name="Group 55"
                                transform="translate(21.523 0.707) rotate(90)"
                            >
                                <line
                                    id="Line_3"
                                    data-name="Line 3"
                                    x2="5.192"
                                    y2="5.431"
                                    transform="translate(5.17 0)"
                                    fill="none"
                                    stroke="#0a141c"
                                    stroke-linecap="round"
                                    stroke-width="1"
                                />
                                <line
                                    id="Line_4"
                                    data-name="Line 4"
                                    x1="5.17"
                                    y2="5.431"
                                    transform="translate(0 0)"
                                    fill="none"
                                    stroke="#0a141c"
                                    stroke-linecap="round"
                                    stroke-width="1"
                                />
                                <line
                                    id="Line_5"
                                    data-name="Line 5"
                                    y2="20.265"
                                    transform="translate(5.181 0.759)"
                                    fill="none"
                                    stroke="#0a141c"
                                    stroke-linecap="round"
                                    stroke-width="1"
                                />
                            </g>
                        </svg>
                        <span class="title">{{ _tr("Explore") }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderTitle from "@/components/custom/HeaderTitle";
import { mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        lightMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice",
            scroll: "scroll/getScroll"
        }),
        brandList() {
            return this.data?.list?.map((item, index) => ({
                path: item?.image?.[0]?.devices[this.device?.type],
                revers: index % 2 === 1,
                teaser: item?.teaser,
                title: item?.title,
                slug: item?.slug,
                brandColor: item.color,
                logo: item.logo
            }));
        },
        imageSpeed() {
            return this.device.size < 1024 ? 0 : 1;
        }
    },
    components: {
        HeaderTitle
    },
    methods: {
        mouseLeave(ev) {
            this.gsap.to(ev.target, {
                scale: "-=.2"
            });
        },
        mouseEnter(ev) {
            this.gsap.to(ev.target, {
                scale: "+=.2"
            });
        },
        changeRoute(slug) {
            this.$router.push({ params: { path2: slug } }).catch(e => {
                console.log(e);
            });
        },
        mobileParallax() {
            if (this.device.size < 1024) {
                let images = document.querySelectorAll(".brand-content .image");
                new this.simpleParallax(images, {
                    customContainer: document.querySelector("body"),
                    scale: 1.5,
                    orientation: "down"
                });
            }
        }
    },
    created() {
        this.$nextTick(() => {
            this.mobileParallax();
        });
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.14, 0.6, 0.3, 0.84);

.brand-list /deep/ {
    display: flex;
    flex-direction: column;
    color: white;
    // overflow: hidden;

    .background-image {
        position: absolute;
        width: 100%;
        opacity: 0.1;
        pointer-events: none;
    }

    .header-title {
        cursor: pointer;
        font-weight: normal;
    }

    .brand-content {
        padding: 50px 75px 0;
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;

        .brand-list-item {
            display: flex;
            align-items: center;
            margin-top: 143px;
            -webkit-tap-highlight-color: transparent;

            &:first-child {
                margin-top: 0;
            }

            &.revers {
                flex-direction: row-reverse;

                .name-container {
                    left: -100px;
                    right: unset;
                    bottom: -50px;
                }

                .content {
                    margin-right: 300px;
                    margin-left: 0;
                }
            }
        }
    }

    .image-container {
        position: relative;
        cursor: pointer;

        .image-contain {
            width: 750px;
            height: 587px;
            overflow: hidden;
            transition: width 0.6s $ease-out;

            .image {
                width: 110%;
                height: 110%;
                top: -5%;
                left: -5%;
                object-fit: cover;
                position: relative;
            }
        }

        .name-container {
            @include circle(360);
            @include center-children;
            position: absolute;
            right: -70px;
            bottom: -30px;
            transition: width, height 0.6s $ease-out;

            svg {
                width: 80%;
                z-index: 1;
                position: relative;
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    width: 60%;
                }
            }

            .bg-container {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                top: 0;
                opacity: 0.83;
                left: 0;
                position: absolute;
            }
        }
    }

    .content {
        text-align: left;
        width: 600px;
        margin-left: 300px;

        .description {
            color: #e5b676;
            font-size: 34px;
            margin-top: 30px;
            transition: all 0.6s $ease-out;
            line-height: 50px;
        }

        .explore-container {
            display: flex;
            align-items: center;
            margin-top: 34px;
            cursor: pointer;
        }

        .arrow-icon {
            margin-right: 15px;

            line {
                stroke: white;
                transition: all 0.6s $ease-out;
            }
        }

        .title {
            transition: all 0.6s $ease-out;
            font-weight: normal;
            font-size: 16px;
            letter-spacing: 0.2em;
            line-height: 16px;
            text-align: left;

            &:hover {
                transition: all 0.6s $ease-out;
                color: #e5b676;
            }
        }
    }
}

@media only screen and (max-width: 1650px) and (min-width: 1440px) {
    .brand-list /deep/ {
        .brand-content {
            padding: 50px 165px 0;
        }

        .header-title {
            font-size: 55px;
        }

        .brand-list-item {
            margin-top: 89px;

            &.revers {
                .content {
                    margin-right: 190px !important;
                    margin-left: 0 !important;
                }
            }
        }

        .content {
            width: 475px !important;
            margin-left: 190px !important;

            .description {
                font-size: 34px;
                line-height: 50px;
            }
        }

        .image-container {
            .image-contain {
                width: 445px;
                height: 348px;
            }

            .name-container {
                @include circle(255);
            }
        }
    }
}

@media only screen and (max-width: 1439px) and (min-width: 1366px) {
    .brand-list /deep/ {
        .brand-content {
            padding: 50px 128px 0;
        }

        .header-title {
            font-size: 52px;
        }

        .brand-list-item {
            margin-top: 89px;

            &.revers {
                .content {
                    margin-right: 190px !important;
                    margin-left: 0 !important;
                }
            }
        }

        .content {
            width: 475px !important;
            margin-left: 190px !important;

            .description {
                font-size: 34px;
                line-height: 50px;
            }
        }

        .image-container {
            .image-contain {
                width: 445px;
                height: 348px;
            }

            .name-container {
                @include circle(250);

                svg {
                    width: 146px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1365px) and (min-width: 1024px) {
    .brand-list /deep/ {
        .brand-content {
            padding: 50px 47px 0;
        }

        .header-title {
            font-size: 34px;
        }

        .brand-list-item {
            margin-top: 89px;

            &.revers {
                .content {
                    margin-right: 130px !important;
                    margin-left: 0 !important;
                }
            }
        }

        .content {
            width: 400px;
            margin-left: 130px !important;

            .description {
                font-size: 21px;
                line-height: 30px;
            }
        }

        .image-container {
            .image-contain {
                width: 400px;
                height: 312px;
            }

            .name-container {
                @include circle(180);

                svg {
                    width: 108px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .brand-list /deep/ {
        .brand-content {
            padding: 30px 47px 0;
            padding-bottom: 0px;

            .brand-list-item {
                display: block;
                margin-top: 89px;
            }
        }

        .header-title {
            font-size: 34px;
            line-height: unset;
        }

        .content {
            margin-top: 30px;
            width: 100%;
            margin-left: 0 !important;
            margin-right: 0 !important;

            .description {
                font-size: 21px;
                line-height: 30px;
            }
        }

        .image-container {
            .image-contain {
                width: 100%;
                height: 527px;
            }

            .name-container {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .brand-list /deep/ {
        .brand-content {
            padding: 30px 16px 0;

            .brand-list-item {
                display: block;
                margin-top: 55px;
            }
        }

        .header-title {
            font-size: 34px;
            line-height: unset;
        }

        .content {
            margin-top: 27px;
            width: 100%;
            margin-left: 0 !important;
            margin-right: 0 !important;

            .description {
                font-size: 21px;
                line-height: 30px;
            }
        }

        .image-container {
            .image-contain {
                width: 100%;
                height: 298px;
            }

            .name-container {
                display: none;
            }
        }
    }
}

.brand-list.lightMode {
    color: black;

    .header-title {
        color: black !important;
    }

    .arrow-icon {
        line {
            stroke: black !important;
        }
    }
}
</style>
